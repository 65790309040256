<script setup lang="ts">
import { PaymentEventLabelMap } from '~/plugins/tracking.client'
import { TrackProfileEventType } from '~/constants/enums'

const { $ROUTE, $router, $gtm, $i18n } = useNuxtApp()

const goToProfilePage = (label: string) => {
  $gtm.trackEvent({
    event: 'edit_profile',
    action: 'edit',
    label,
    category: 'Payment info',
    component: 'payment overview',
    position: 'main content',
    language: unref($i18n.locale),
    _clear: true,
  })
  $router.push($ROUTE.PROFILE_PAYMENT_INFO)
}
</script>

<template>
  <div
    v-if="
      $unleash.features['payments-change-iban'] ||
      $unleash.features['payments-pay-periodicity']
    "
    class="flex flex-col gap-8 md:flex-row md:gap-2"
  >
    <PaymentIBAN
      v-if="$unleash.features['payments-change-iban']"
      class="w-full md:w-1/2"
      :iban="$auth.profile.iban"
      card-icon="arrow-right"
      @click="goToProfilePage(PaymentEventLabelMap[TrackProfileEventType.IBAN])"
    />

    <PaymentSchedule
      v-if="$unleash.features['payments-pay-periodicity']"
      class="w-full md:w-1/2"
      :iban="$auth.profile.iban"
      card-icon="arrow-right"
      :sub-title="$t('components.paymentInfo.paymentSchedule.defaultSubTitle')"
      :payment-schedule-type="$auth.profile.paymentSchedule"
      @click="
        goToProfilePage(
          PaymentEventLabelMap[TrackProfileEventType.PaymentSchedule],
        )
      "
    />
  </div>
</template>
